import request from '@/utils/request'


// 查询推广记录列表
export function listRecord(query) {
  return request({
    url: '/referrer/record/list',
    method: 'get',
    params: query
  })
}


export function statisticPage(query) {
  return request({
    url: '/referrer/record/statisticPage',
    method: 'get',
    params: query
  })
}

// 查询推广记录分页
export function pageRecord(query) {
  return request({
    url: '/referrer/record/page',
    method: 'get',
    params: query
  })
}

// 查询推广记录详细
export function getRecord(data) {
  return request({
    url: '/referrer/record/detail',
    method: 'get',
    params: data
  })
}

// 新增推广记录
export function addRecord(data) {
  return request({
    url: '/referrer/record/add',
    method: 'post',
    data: data
  })
}

// 修改推广记录
export function updateRecord(data) {
  return request({
    url: '/referrer/record/edit',
    method: 'post',
    data: data
  })
}

// 删除推广记录
export function delRecord(data) {
  return request({
    url: '/referrer/record/delete',
    method: 'post',
    data: data
  })
}
